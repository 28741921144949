import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faGoogle, faResearchgate } from '@fortawesome/free-brands-svg-icons'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`maurice`, `quach`, `website`]} />
    <div className="d-flex flex-column text-center justify-content-center align-items-center pb-5" style={{ height: '100%' }}>
      <h1>Maurice Quach</h1>
      {/* <h2></h2>
      <h3></h3>
      <h4></h4> */}
      <div className="mt-2 icons">
        <a href="https://github.com/mauriceqch" style={{ color: 'black' }}>
          <FontAwesomeIcon icon={faGithub} size="2x" className="mr-2" />
        </a>
        <a href="https://www.linkedin.com/in/maurice-quach" style={{ color: '#0077B5' }}>
          <FontAwesomeIcon icon={faLinkedin} size="2x" className="mr-2" />
        </a>
        <a href="https://scholar.google.fr/citations?user=atvnc2MAAAAJ&hl=fr" style={{ color: '#0077B5' }}>
          <FontAwesomeIcon icon={faGoogle} size="2x" className="mr-2"/>
        </a>
        <a href="https://www.researchgate.net/profile/Maurice_Quach" style={{ color: '#ADB5BD' }}>
          <FontAwesomeIcon icon={faResearchgate} size="2x" className="mr-2"/>
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
